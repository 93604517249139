<template>
  <div class="competence">
    <Header back="返回" index="首页" beforeTitle="操作权限" title="权限管理" titleOne="权限管理" />
    <div class="content">
      <h1>操作权限</h1>
      <el-tree
        :data="operadata"
        show-checkbox
        default-expand-all
        node-key="id"
        :props="defaultProps"
        check-on-click-node
        ref="tree"
        @check-change="checkChange"
        :render-content="renderContent"
        @node-expand="handleExpand"
        @node-click="nodeClick"
        @check="checkClick"
      ></el-tree>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      defaultKey: [],
      operadata: [],
      defaultProps: {
        children: "children",
        label: "title",
        id: "id",
      },
      rules: "",
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    this.changeCss();
  },
  created () {
    this.axios
      .get("/api/rule/list", {
        params: {
          role_id: this.meber_id,
        },
      })
      .then((res) => {
        console.log(res);
        this.operadata = res.data;
        this.getMenu(this.operadata);
        setTimeout(() => {
          this.changeCss();
        }, 10);
      })
      .catch((res) => {
        console.log("err:", res);
      });
    this.handleExpand()
  },
  methods: {
    checkClick (a, b, c) {
      console.log(a, b, c, '复选框')
      a.checked = !a.checked
      // if (a.checked) {
      //   this.defaultKey.push(a.id)
      //   this.defaultKey.push(b.halfCheckedKeys)
      // }
    },
    nodeClick (a, b, c) {
      console.log(a, b, c)
      b.checked = !b.checked
    },

    handleExpand () {//节点被展开时触发的事件
      //因为该函数执行在renderContent函数之前，所以得加定时
      setTimeout(() => {
        this.changeCss();
      }, 10);
    },
    changeCss () {
      var levelName = document.getElementsByClassName("foo"); // levelname是上面的最底层节点的名字
      for (var i = 0; i < levelName.length; i++) {
        // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
        levelName[i].parentNode.style.cssFloat = "left"; // 最底层的节点，包括多选框和名字都让他左浮动
        levelName[i].parentNode.style.styleFloat = "left";
        levelName[i].parentNode.onmouseover = function () {
          this.style.backgroundColor = "#fff";
        };
      }
      var fooer = document.getElementsByClassName("fooer"); // fooer是上面的最底层节点的名字
      for (var j = 0; j < fooer.length; j++) {
        // cssFloat 兼容 je6-8  styleFloat 兼容je9及标准浏览器
        fooer[j].parentNode.style.fontSize = 18 + 'px'
        fooer[j].parentNode.style.fontWeight = '700'
        fooer[j].parentNode.style.color = '#000'
        fooer[j].parentNode.style.margin = 10 + 'px' + ' ' + 0
      }
    },
    renderContent (h, { node, data, store }) {
      // console.log(h, node, data, store)
      let classname = "";
      if (node.level === 1) {
        classname = "fooer";
      }
      if (node.level === 2 && node.childNodes.length === 0) {
        classname = "foo";
      }
      // 由于项目中有三级菜单也有四级级菜单，就要在此做出判断
      if (node.level === 4) {
        classname = "foo";
      }
      if (node.level === 3 && node.childNodes.length === 0) {
        classname = "foo";
      }
      return h(
        "p",
        {
          class: classname
        },
        node.label
      );
    },
    checkChange (item, node, self) {
      const anode = this.$refs.tree.getNode(item);
      !anode.checked ? this.findchildren(anode.parent) : "";
    },
    findchildren (current) {
      const fnode = this.$refs.tree.getNode(current);
      if (!fnode.isLeaf) {
        fnode.indeterminate = true;
        fnode.checked = true;
      }
    },
    getMenu (menuData) {
      menuData.forEach((item) => {
        if (item.checked) {
          this.defaultKey.push(item.id);
        }
        if (item.children) {
          this.getMenu(item.children);
        }
      });
      this.defaultKey.forEach((el) => {
        this.$nextTick(() => {
          this.$refs.tree.setChecked(el, true);
        });
      });
    },
    returnPage () {
      this.$router.go(-1);
    },
    onSubmit () {
      // this.defaultKey = this.$refs.tree.getCheckedKeys();
      this.defaultKey = new Set([...this.$refs.tree.getCheckedKeys(), ...this.$refs.tree.getHalfCheckedKeys()])
      // var Rules = this.defaultKey.toString();
      var Rules = [...this.defaultKey].toString()
      this.rules = Rules;
      this.btnLoading = true
      this.axios
        .post("/api/rule/update", {
          role_id: this.meber_id,
          rules: this.rules,
        })
        .then((res) => {
          this.$router.push("/authority/index");
          this.$message({
            type: "success",
            message: "保存成功!",
          });
          this.btnLoading = false
        }).catch(() => {
          this.btnLoading = false
        })
    },
  },
};
</script>

<style scoped>
/* div/deep/.el-tree-node__content:hover {
  background: none;
} */
</style>
